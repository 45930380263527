/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "RegisterResult",
  data: function data() {
    return {
      checkState: ''
    };
  },
  computed: {},
  methods: {},
  created: function created() {},
  mounted: function mounted() {
    this.checkState = this.$route.query.state || this.$route.params.state.split(',')[1];
  },
  destroyed: function destroyed() {}
};